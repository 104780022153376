/**
 * Based on
 *
 *  - reset.css 2.0 by Eric Meyer
      (public domain)
 *    http://meyerweb.com/eric/tools/css/reset/
 *
 *  - normalize.css 8.0.1 by Nicolas Gallagher and Jonathan Neal
 *    (licensed under MIT)
 *    https://github.com/necolas/normalize.css
 *
 *  - Reboot from Bootstrap 4.5.3
 *    (licensed under MIT)
 *    https://github.com/twbs/bootstrap
 */
/**
 * IE10+ doesn't honor `<meta name="viewport">` in some cases
 */
@-ms-viewport {
  width: device-width; }

/**
 * general reset
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/**
 * HTML5 display-role reset for older browsers
 */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, summary {
  display: block; }

/**
 * inherit box model for all elements
 */
*,
*::before,
*::after {
  box-sizing: inherit; }

/**
 * html root rules
 * 1. set border-box for inheritance
 * 2. avoid 300ms click delay on touch devices that support the `touch-action`
 *    CSS property
 * 3. Prevent adjustments of font size after orientation changes in IE, on
 *    Windows Phone and iOS.
 * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
 *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
 * 5. Change the default tap highlight to be completely transparent in iOS.
 */
html {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  touch-action: manipulation;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/**
 * body rules
 * 1. reset line-height to 1
 * 2. set base font-family to sans-serif
 * 3. Set an explicit initial text-align value so that we can later use the
 *    `inherit` value on things like `<th>` elements.
 */
body {
  /* 1 */
  line-height: 1;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  text-align: left; }

/**
 * Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
 * on elements that programmatically receive focus but wouldn't normally show a visible
 * focus outline. In general, this would mean that the outline is only applied if the
 * interaction that led to the element receiving programmatic focus was a keyboard interaction,
 * or the browser has somehow determined that the user is primarily a keyboard user and/or
 * wants focus outlines to always be presented.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
 * and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/
 */
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

/**
 * Lists
 */
ol, ul {
  list-style: none; }

/**
 * Quotes
 */
blockquote, q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

/**
 * Tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  caption-side: bottom; }

/**
 * Table Headers
 * 1. Matches default `<td>` alignment by inheriting from the `<body>`, or the
 *    closest parent with a set `text-align`.
 * 2. Fix alignment for Safari
 */
th {
  /* 1 */
  text-align: inherit;
  /* 2 */
  text-align: -webkit-match-parent; }

/**
 * Horizontal Lines
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  overflow: visible; }

/**
 * Preformatted Text
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Don't allow content to break outside
 * 3. We have @viewport set which causes scrollbars to overlap content in IE11
 *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
 *    counteract.
 */
pre,
code,
kbd,
samp {
  /* 1 */
  font-family: monospace, monospace; }

pre {
  /* 2 */
  overflow: auto;
  /* 3 */
  -ms-overflow-style: scrollbar; }

/**
 * Links
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  /* 1 */
  background-color: transparent;
  /* 2 */
  -webkit-text-decoration-skip: objects; }

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Prevent the text-decoration to be skipped.
 */
abbr[title] {
  /* 1 */
  border-bottom: 0;
  /* 2 */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* 3 */
  cursor: help;
  /* 4 */
  text-decoration-skip-ink: none; }

address {
  font-style: normal;
  line-height: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/**
 * Prevent `em` being affected from global reset
 */
em {
  font-style: italic; }

/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none; }

/**
 * Hide SVG overflow in IE
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * Remove the default `border-radius` that macOS Chrome adds.
 * Details at https://github.com/twbs/bootstrap/issues/24093
 */
button {
  border-radius: 0; }

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 * Credit: https://github.com/suitcss/base/
 */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/**
 * form element resets
 * 1. Remove the margin in Firefox and Safari
 * 2. inherit font rules
 */
input,
button,
select,
optgroup,
textarea {
  /* 1 */
  margin: 0;
  /* 2 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="reset"],
[type="submit"],
[type="button"] {
  /* 2 */
  -webkit-appearance: button; }

/**
 * Remove the default appearance of temporal inputs to avoid a Mobile Safari
 * bug where setting a custom line-height prevents text from being vertically
 * centered within the input.
 * See https://bugs.webkit.org/show_bug.cgi?id=139848
 * and https://github.com/twbs/bootstrap/issues/11266
 */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
  overflow: auto;
  resize: vertical; }

/**
 * Show the overflow in IE.
 */
button,
input {
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button,
select {
  text-transform: none; }

/**
 * Set the cursor for non-`<button>` buttons
 * Details at https://github.com/twbs/bootstrap/pull/30562
 */
[role="button"] {
  cursor: pointer; }

/**
 * Remove the inheritance of word-wrap in Safari.
 * See https://github.com/twbs/bootstrap/issues/24990
 */
select {
  word-wrap: normal; }

/**
 * Remove inner border and padding from Firefox, but don't restore the outline
 * like Normalize.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * 1. Add the correct box sizing in IE 10-
 * 2. Remove the padding in IE 10-
 */
input[type="radio"],
input[type="checkbox"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
  outline: 0 !important; }

/**
 * Browsers set a default `min-width: min-content` on fieldsets,
 * unlike e.g. `<div>`s, which have `min-width: 0` by default.
 * So we reset that to ensure fieldsets behave more like a standard block element.
 * See https://github.com/twbs/bootstrap/issues/12359
 * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
 */
fieldset {
  min-width: 0; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Set display to block for all browsers
 */
legend {
  /* 1 */
  max-width: 100%;
  white-space: normal;
  /* 2 */
  color: inherit;
  /* 3 */
  display: block; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * 1. Remove the default vertical scrollbar in IE 10+.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical; }

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  /* 1 */
  -webkit-appearance: textfield;
  /* 2 */
  outline-offset: -2px; }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  font: inherit; }

/**
 * Correct element display for output
 */
output {
  display: inline-block; }

/**
 * Add the correct display in IE 10+.
 */
template {
  display: none; }

/**
 * Always hide an element with the `hidden` HTML attribute (from PureCSS).
 * Needed for proper display in IE 10-.
 */
[hidden] {
  display: none; }

/** UTILS **/
/** BASE **/
h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 122%;
  color: #003D71;
  position: relative; }

#root {
  display: flex;
  flex-flow: column; }

.wrapper {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

main.content {
  margin: 20px 0; }

.text-center {
  text-align: center !important; }

.row-form {
  display: flex;
  flex-wrap: wrap; }
  .row-form__line {
    display: flex;
    padding: 0 16px 12px 0;
    flex-direction: column;
    width: 50%;
    margin-bottom: 26px;
    position: relative;
    flex-grow: 1; }
    .row-form__line.fullW {
      width: 100%; }
    .row-form__line label {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      font-style: italic;
      flex: 1; }

input {
  padding: 16px;
  width: auto;
  outline: none; }

textarea {
  padding: 16px;
  background: #fff;
  outline: none;
  min-height: 120px; }

.error {
  padding: 12px 0;
  color: #f44336;
  font-style: italic;
  position: absolute;
  left: 0;
  bottom: -25px; }

body {
  font-family: 'Roboto', sans-serif;
  color: #222222;
  font-size: 1rem;
  line-height: 1;
  background-color: #f5f7f8;
  margin: 0; }

main {
  margin-top: 80px;
  display: flex;
  flex-flow: column; }

.border-blue {
  border: 1px solid #8ebdc5; }

.box {
  color: #222;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1.875rem;
  margin-bottom: 1.25rem;
  position: relative; }

.has-tooltip {
  color: #000 !important;
  position: relative; }
  .has-tooltip.cut-text:after {
    content: '';
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%); }
  .has-tooltip .content {
    margin-top: 0 !important; }
  .has-tooltip:hover > .card {
    visibility: visible; }
    .has-tooltip:hover > .card ul {
      flex-direction: column;
      justify-content: flex-start;
      align-items: self-start;
      width: 100%; }
      .has-tooltip:hover > .card ul li {
        color: #003D71; }
        .has-tooltip:hover > .card ul li:last-child {
          margin-bottom: 0; }
        .has-tooltip:hover > .card ul li sup {
          background-color: transparent !important;
          color: #00B5D4; }

ul li {
  padding-bottom: 8px;
  position: relative; }
  ul li.active:after {
    content: '';
    position: absolute;
    top: 100%;
    height: 2px;
    width: 100%;
    left: 0;
    border: 1px solid; }

.star-rating svg {
  max-width: 16px; }

.global-search {
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 16px;
  min-width: 290px;
  border: 1px solid #8fbdc5;
  margin-left: 16px; }

.filter-list-input {
  background: #FFFFFF;
  padding: 8px;
  color: #99999B;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  border: 1px solid #dfdfe2; }

.tag {
  padding: 0px 8px;
  border-radius: 4px;
  display: block !important;
  margin-bottom: 8px; }
  .tag--blue {
    background: #00b5d3;
    color: white; }
    .tag--blue:not(:last-child) {
      margin-right: 8px; }

.quill {
  background-color: #ffffff; }

.button {
  padding: 12px;
  outline: none;
  background: transparent;
  border-radius: 0;
  appearance: none;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center; }
  .button:hover, .button:active, .button:visited {
    outline: none;
    box-shadow: none; }
  .button.func-button:active {
    transform: scale(0.95); }
  .button:disabled {
    opacity: .2; }
  .button > svg {
    margin-right: 8px;
    margin-left: 8px; }
  .button--rounded {
    border-radius: 6px; }
  .button--medium {
    padding-left: 68px;
    padding-right: 68px; }
  .button--c-black {
    color: #000 !important; }
  .button--blue {
    background: #00B5D4 !important;
    color: #fff !important; }
  .button--primary {
    background: #003D71 !important;
    color: #fff !important; }
  .button--blue-outline {
    background: transparent !important;
    border: 1px solid #00B5D4 !important;
    color: #00B5D4 !important; }
  .button--white {
    background: #fff !important;
    color: #00B5D4; }
    .button--white:not(:last-child) {
      border-right: 1px solid #E4E6E7; }
  .button--outline {
    background: transparent;
    color: #00B5D4;
    border-color: #00B5D4; }
  .button--full-outline {
    background: transparent !important;
    color: #00B5D4;
    text-decoration: none;
    border: none !important;
    outline: none; }

table.listing {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 61, 113, 0.05);
  border-radius: 8px; }
  table.listing thead th {
    padding: 32px 8px 16px;
    position: relative;
    color: #99999B;
    cursor: pointer; }
    table.listing thead th:first-child {
      padding-left: 16px; }
    table.listing thead th.active_sorting {
      color: #000; }
      table.listing thead th.active_sorting:before {
        content: '';
        position: absolute;
        background: #f2fafc;
        height: 100%;
        left: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        bottom: -12px;
        width: 100%; }
      table.listing thead th.active_sorting span:last-child {
        background-color: #000;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center; }
        table.listing thead th.active_sorting span:last-child svg path {
          fill: #fff; }
    table.listing thead th.opened span:nth-child(2) {
      background-color: #99999B;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center; }
      table.listing thead th.opened span:nth-child(2) svg path {
        fill: #fff; }
    table.listing thead th:last-child:before {
      width: calc(100% - 16px); }
    table.listing thead th span {
      display: table;
      margin-top: 4px;
      position: relative; }
    table.listing thead th:not(:last-child):after {
      content: '';
      position: absolute;
      height: calc(100% - 32px);
      width: 1px;
      background: #F2F2F4;
      bottom: 0;
      right: 0; }
    table.listing thead th .filter-box {
      position: absolute;
      padding: 24px;
      background: #FFFFFF;
      box-shadow: 4px 0px 8px rgba(0, 61, 113, 0.05), 8px 0px 80px rgba(0, 61, 113, 0.1);
      border-radius: 8px;
      width: 320px;
      z-index: 2040;
      top: 100%; }
      table.listing thead th .filter-box--right {
        right: 16px; }
      table.listing thead th .filter-box hr {
        border: 1px solid #F2F2F4; }
      table.listing thead th .filter-box button {
        appearance: none;
        background: transparent;
        border: 0;
        color: #000;
        outline: none; }
        table.listing thead th .filter-box button:not(:last-child) {
          margin-bottom: 16px; }
  table.listing tbody tr {
    position: relative; }
    table.listing tbody tr:last-child td {
      padding-bottom: 32px; }
      table.listing tbody tr:last-child td:after {
        height: calc(100% - 42px) !important;
        bottom: 16px !important; }
      table.listing tbody tr:last-child td:before {
        display: none; }
    table.listing tbody tr .comment-line {
      position: relative;
      display: inline-block; }
      table.listing tbody tr .comment-line > .more {
        position: absolute;
        bottom: -5px;
        z-index: 2040;
        cursor: pointer;
        color: #00b5d4;
        left: 50%;
        transform: translateX(-50%); }
      table.listing tbody tr .comment-line--big {
        max-height: 56px;
        overflow: hidden;
        word-break: break-word; }
        table.listing tbody tr .comment-line--big:hover {
          max-height: initial; }
          table.listing tbody tr .comment-line--big:hover:after {
            visibility: hidden; }
        table.listing tbody tr .comment-line--big:after {
          content: '';
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%); }
    table.listing tbody tr td {
      padding: 24px 8px 16px 8px;
      position: relative;
      color: #9b9b9b;
      vertical-align: middle;
      letter-spacing: 0.50px; }
      table.listing tbody tr td.active_sorting:before {
        content: '';
        position: absolute;
        background: #f2fafc;
        height: calc(100%);
        left: 0;
        border-bottom: 2px solid #f2f2f4;
        bottom: -12px; }
      table.listing tbody tr td ul {
        display: flex;
        flex-wrap: wrap;
        max-width: 220px;
        align-items: center; }
        table.listing tbody tr td ul li {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          margin-right: 8px; }
          table.listing tbody tr td ul li sup {
            width: 20px;
            height: 20px;
            background: #0eb5d3;
            font-size: 12px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            top: 0;
            margin-left: 4px;
            font-weight: bold; }
      table.listing tbody tr td .disabled {
        opacity: 0.2;
        pointer-events: none; }
      table.listing tbody tr td .card {
        background: #FFFFFF;
        box-shadow: 4px 0px 8px rgba(0, 61, 113, 0.05), 8px 0px 80px rgba(0, 61, 113, 0.1);
        border-radius: 8px;
        position: absolute;
        padding: 24px 16px;
        width: 240px;
        left: calc(100% + 6px);
        top: calc(50% - 4px);
        transform: translateY(-50%);
        visibility: hidden;
        pointer-events: none;
        z-index: 2040; }
        table.listing tbody tr td .card > span {
          position: absolute;
          left: -12px;
          top: 50%;
          transform: translateY(-50%); }
        table.listing tbody tr td .card .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%; }
          table.listing tbody tr td .card .content img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 12px; }
          table.listing tbody tr td .card .content h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 141%;
            text-align: center;
            color: #000000; }
          table.listing tbody tr td .card .content strong {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            text-align: center;
            color: #003D71; }
      table.listing tbody tr td:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #F2F2F4;
        bottom: -12px;
        left: 0; }
      table.listing tbody tr td:first-child {
        padding-left: 16px; }
        table.listing tbody tr td:first-child:before {
          right: 0;
          left: initial; }
      table.listing tbody tr td:first-child:before, table.listing tbody tr td:last-child:before {
        width: calc(100% - 16px); }
      table.listing tbody tr td button {
        background: #003D71;
        border-radius: 8px;
        padding: 6px 12px;
        border: 0;
        color: #fff;
        font-size: 14px;
        position: relative;
        z-index: 200; }
        table.listing tbody tr td button:hover + .card {
          visibility: visible;
          pointer-events: initial;
          z-index: 2040; }
      table.listing tbody tr td span {
        display: table;
        line-height: 145%;
        font-size: 14px;
        box-sizing: border-box;
        position: relative; }
        table.listing tbody tr td span.link {
          color: #000;
          max-width: 316px;
          font-weight: bold;
          text-decoration: underline; }
        table.listing tbody tr td span.client {
          max-width: 190px;
          color: #00B5D4; }
        table.listing tbody tr td span.black {
          color: #000; }
      table.listing tbody tr td:not(:last-child):after {
        content: '';
        position: absolute;
        height: calc(100% - 24px);
        width: 1px;
        background: #F2F2F4;
        bottom: 0;
        right: 0; }

.listing-pagination {
  justify-content: center !important;
  align-items: center; }
  .listing-pagination .prev, .listing-pagination .next {
    cursor: pointer; }
  .listing-pagination .pagination-numbers {
    padding: 0 22px; }
    .listing-pagination .pagination-numbers input {
      background: #FFFFFF;
      border-left: 1px solid #00B5D4;
      border-top: 1px solid #00B5D4;
      border-bottom: 1px solid #00B5D4;
      border-right: none;
      padding: 8px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #00B5D4;
      text-align: center;
      outline: none; }
    .listing-pagination .pagination-numbers .total-pages {
      background: rgba(153, 153, 155, 0.1);
      border-right: 1px solid #BFBFC2;
      border-top: 1px solid #BFBFC2;
      border-bottom: 1px solid #BFBFC2;
      border-left: 1px solid #BFBFC2;
      padding: 8px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center; }

.ui-select {
  width: 170px;
  padding: 14px;
  border: 1px solid #8ebdc5;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative; }
  .ui-select .choices {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    border: 1px solid #8ebdc5;
    left: 0;
    z-index: 1040;
    max-height: 162px;
    overflow: scroll; }
    .ui-select .choices span {
      padding: 12px 16px;
      background: #fff;
      color: #0C0C0E !important;
      width: 100%;
      display: block; }
      .ui-select .choices span:hover {
        background: #F2F2F4;
        color: #00B5D4 !important; }

.ui-dynamic-dropdown {
  width: 100%;
  color: #fff;
  position: relative;
  z-index: 1; }
  .ui-dynamic-dropdown--open {
    z-index: 14; }
  .ui-dynamic-dropdown > span {
    padding: 12px 16px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    color: #fff !important;
    cursor: pointer;
    position: relative;
    background: #003D71;
    border-radius: 6px;
    z-index: 1041;
    margin-right: 0 !important; }
    .ui-dynamic-dropdown > span label {
      color: inherit;
      font-size: 17px;
      line-height: 100%; }
  .ui-dynamic-dropdown .options {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 61, 113, 0.05), 0px 8px 80px rgba(0, 61, 113, 0.1), inset 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 100%;
    z-index: 1040;
    border: 1px solid #c4c4c4;
    max-height: 186px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column; }
    .ui-dynamic-dropdown .options__item {
      padding: 9px 16px !important;
      font-weight: normal;
      font-size: 17px;
      line-height: 141%;
      color: #262628;
      cursor: pointer;
      min-width: initial !important;
      display: block !important; }
      .ui-dynamic-dropdown .options__item--selected {
        background: #d9d9d9; }
      .ui-dynamic-dropdown .options__item:hover {
        background: #F2F2F4; }
      .ui-dynamic-dropdown .options__item:first-child {
        padding: 12px 16px 9px !important; }

.sliding-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: auto; }
  .sliding-modal__visible {
    visibility: visible;
    z-index: 99999999; }
    .sliding-modal__visible .sliding-modal__content {
      visibility: visible;
      opacity: 1;
      transform: translateX(0); }
  .sliding-modal__backdrop {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%; }
  .sliding-modal__content {
    width: 50vw;
    max-width: 900px;
    background: #ECF8FB;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0s,opacity .3s 0s,transform .3s 0s;
    transform: translateX(100%); }
    .sliding-modal__content .close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      opacity: 1; }
    .sliding-modal__content header {
      display: flex;
      flex: 1 1;
      align-items: center;
      background: #fff;
      padding-left: 32px;
      padding-right: 68px;
      height: 100px;
      justify-content: space-between; }
    .sliding-modal__content .content {
      padding: 32px;
      display: flex;
      flex-direction: column; }

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999999; }
  .modal-container__backdrop {
    background: #000000;
    opacity: 0.4;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .modal-container__content {
    width: 800px;
    background: #FFFFFF;
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    overflow: auto; }
    .modal-container__content .radioset-buttons {
      display: flex;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
      .modal-container__content .radioset-buttons button {
        appearance: none;
        width: 50px;
        height: 50px;
        border: 1px solid #00B5D4;
        background: transparent;
        color: #00B5D4;
        outline: none; }
        .modal-container__content .radioset-buttons button.active {
          background: #00B5D4;
          color: #fff; }
    .modal-container__content table {
      margin-top: 40px;
      width: 100%; }
      .modal-container__content table thead th {
        color: #00B5D4;
        width: 20%;
        padding-bottom: 40px;
        text-align: center; }
        .modal-container__content table thead th:first-child {
          color: #99999B;
          width: 40%;
          text-align: left; }
      .modal-container__content table tbody tr {
        color: #003D71;
        width: 20%; }
        .modal-container__content table tbody tr td {
          padding: 20px 0;
          border-top: 1px solid #F2F2F4;
          color: #003D71;
          text-align: center; }
          .modal-container__content table tbody tr td:first-child {
            color: #00B5D4;
            width: 40%;
            text-align: left; }
  .modal-container header {
    background: rgba(255, 117, 0, 0.19);
    border-radius: 8px 8px 0px 0px;
    padding: 24px;
    display: flex;
    justify-content: center; }
    .modal-container header.blue {
      background: #E0F3F8; }
  .modal-container .content {
    padding: 24px 50px;
    margin: 0; }
    .modal-container .content h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 122%;
      color: #003D71;
      text-align: center; }
    .modal-container .content .title {
      font-weight: 500;
      font-size: 20px;
      color: #003D71;
      text-align: center;
      padding: 0;
      margin-top: 0; }
    .modal-container .content p {
      padding: 0 50px;
      text-align: center;
      margin-top: 24px; }
    .modal-container .content .actions {
      display: flex;
      justify-content: center;
      margin-top: 32px; }
      .modal-container .content .actions button {
        padding: 12px 55px;
        appearance: none;
        border: 0;
        outline: none; }
        .modal-container .content .actions button:disabled {
          opacity: .2; }
        .modal-container .content .actions button:not(:last-child) {
          margin-right: 24px; }
        .modal-container .content .actions button.blue {
          background: #00B5D4;
          border: 1px solid #00B5D4;
          color: #fff; }
        .modal-container .content .actions button.white {
          background: #fff;
          color: #00B5D4;
          border: 1px solid #00B5D4; }
        .modal-container .content .actions button.outline {
          color: #00B5D4;
          border: 1px solid #00B5D4;
          background: transparent; }
    .modal-container .content .form-row {
      margin-top: 20px;
      flex-direction: column; }
      .modal-container .content .form-row span.price {
        position: absolute;
        top: 8px;
        right: 0;
        background: #003d71;
        height: calc(100% - 8px);
        border-radius: 2px;
        color: #fff;
        padding: 0 10px;
        line-height: 43px;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        align-items: center; }
      .modal-container .content .form-row label {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 141%;
        color: #003D71; }
      .modal-container .content .form-row > div {
        position: relative;
        width: 100%; }
        .modal-container .content .form-row > div .search__results {
          padding: 24px 16px;
          background: #FFFFFF;
          box-shadow: 0px 4px 8px rgba(0, 61, 113, 0.05), 0px 8px 80px rgba(0, 61, 113, 0.1), inset 0px 1px 0px rgba(0, 0, 0, 0.05);
          top: 100%;
          left: 0;
          width: 100%;
          z-index: 2040;
          max-height: 250px;
          overflow-y: auto; }
          .modal-container .content .form-row > div .search__results .consultant-line {
            display: flex;
            width: 100%;
            align-items: center;
            transition: background-color 350ms linear;
            cursor: pointer;
            padding: 8px; }
            .modal-container .content .form-row > div .search__results .consultant-line:hover {
              background: #eee;
              border-radius: 4px; }
            .modal-container .content .form-row > div .search__results .consultant-line:not(:last-child) {
              margin-bottom: 8px; }
            .modal-container .content .form-row > div .search__results .consultant-line img {
              width: 32px;
              height: 32px;
              object-fit: cover;
              border-radius: 32px;
              margin-right: 16px; }
      .modal-container .content .form-row input[type='text'] {
        width: 100%;
        background: #F2F2F4;
        appearance: none;
        margin-top: 8px;
        padding: 12px 16px;
        border: 0;
        outline: none; }
    .modal-container .content .date .input-clone {
      background: #fff;
      justify-content: space-between; }
      .modal-container .content .date .input-clone > div {
        display: flex;
        align-items: center; }
      .modal-container .content .date .input-clone svg {
        margin-left: 24px; }
    .modal-container .content .plage input {
      display: none; }
    .modal-container .content .plage label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .modal-container .content .plage label:before {
        content: '';
        width: 28px;
        height: 28px;
        background: #F2F2F4;
        border-radius: 50%;
        position: relative;
        display: inline-block;
        margin-right: 12px; }
      .modal-container .content .plage label.selected:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background: #00B5D4;
        border-radius: 50%;
        top: 8px;
        left: 8px; }
      .modal-container .content .plage label:not(:last-child) {
        margin-right: 30px; }
    .modal-container .content .buttons-day {
      display: flex;
      justify-content: space-between;
      margin-top: 8px; }
      .modal-container .content .buttons-day button {
        background: transparent;
        border: 0;
        font-size: 17px;
        color: #4D4D4F;
        outline: none;
        position: relative; }
        .modal-container .content .buttons-day button.selected:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          top: 9px;
          left: 50%;
          background: #00B5D4;
          border-radius: 50%;
          transform: translateX(-50%); }
        .modal-container .content .buttons-day button:before {
          content: '';
          position: relative;
          width: 28px;
          height: 28px;
          display: block;
          background: #fff;
          border-radius: 50%;
          margin-bottom: 8px; }
    .modal-container .content .blue-text {
      font-weight: normal;
      font-size: 17px;
      line-height: 141%;
      color: #003D71;
      text-align: left;
      padding: 0; }
      .modal-container .content .blue-text strong {
        display: block;
        margin-top: 4px; }

.view-action-modal .actions {
  position: sticky;
  bottom: 0;
  background: #f1f1f3;
  width: calc(100% + 100px);
  margin-left: -50px;
  padding: 12px; }

.t-label {
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  color: #737375;
  font-size: 14px; }
  .t-label-large {
    font-size: 20px;
    color: #00B5D4; }
  .t-label-small {
    line-height: 145%;
    color: #000000; }
  .t-label-medium {
    line-height: 145%;
    color: #00B5D4;
    font-size: 17px; }
  .t-label-gray {
    font-size: 20px;
    line-height: 140%;
    color: #4D4D4F; }
  .t-label-gray-mid {
    font-size: 14px;
    line-height: 140%;
    color: #737375; }
  .t-label-gray-dark {
    font-size: 20px;
    line-height: 140%;
    color: #4D4D4F;
    position: relative; }

.t-base {
  font-family: "filson-pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  color: #00B5D4; }
  .t-base-small {
    font-size: 14px; }
  .t-base-medium {
    font-size: 17px !important;
    line-height: 141% !important;
    color: #0C0C0E !important; }
  .t-base-large {
    font-size: 20px;
    line-height: 140%;
    color: #000000; }

.t-header {
  font-family: "filson-pro", sans-serif;
  font-style: normal;
  line-height: 135%;
  color: #003D71;
  font-size: 24px;
  font-weight: bold; }
  .t-header-large {
    font-size: 30px;
    line-height: 36px;
    color: #222222;
    font-weight: bold; }
  .t-header-small {
    font-size: 20px;
    color: #00B5D4; }
  .t-header-x-large {
    font-size: 20px;
    color: #000;
    font-weight: 700; }

.u-color-primary {
  color: #003D71 !important; }

.text-underline {
  text-decoration: underline !important; }

.c-white {
  color: white !important; }

.c-blue {
  color: #00B5D4 !important; }

.c-red {
  color: red !important; }

.c-black {
  color: #000000 !important; }

.c-orange {
  color: #FFA300 !important; }

.bg-red {
  background: #f44336; }

.bg-green {
  background: #8bc34a; }

/** COMPONENTS **/
.header {
  padding: 16px 0;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 999999;
  top: 0;
  background: #fff; }
  .header__wrapper {
    align-items: center; }
    .header__wrapper .brand {
      display: flex;
      align-items: center;
      height: auto; }
      .header__wrapper .brand svg {
        margin-right: 16px;
        max-width: 48px; }
    .header__wrapper .navigation .navigation__link {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600;
      color: #313131;
      letter-spacing: 1px;
      margin-right: 16px;
      cursor: pointer; }
  .header .admin-menu button {
    background: #fff;
    padding: 8px 0; }
  .header .admin-menu__sub-menu {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    min-width: 220px;
    background: #fff;
    z-index: 14;
    border: 1px solid #e1e1e1; }
    .header .admin-menu__sub-menu button {
      width: 100%;
      text-align: right;
      justify-content: flex-end;
      outline: none;
      position: relative;
      padding: 12px;
      box-sizing: border-box; }
      .header .admin-menu__sub-menu button:hover {
        background: #eee; }
      .header .admin-menu__sub-menu button:not(:last-child)::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        right: 0;
        background: #e1e1e1; }

footer.mainfooter {
  padding: 15px 0; }

.multiple-select-search {
  background: #fff; }
  .multiple-select-search .search-field {
    flex-wrap: wrap; }
  .multiple-select-search .results {
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    background: #fff;
    z-index: 100;
    flex-direction: column;
    -webkit-box-shadow: 5px 5px 15px 5px #b5b5b5;
    box-shadow: 5px 5px 15px 5px #efefef; }
    .multiple-select-search .results span {
      padding: 16px 8px;
      cursor: pointer;
      width: 100%; }
      .multiple-select-search .results span:not(:last-child) {
        border-bottom: 1px solid #efefef; }
  .multiple-select-search input {
    padding-left: 16px;
    width: 100%; }
  .multiple-select-search .tags {
    padding: 8px;
    display: flex;
    align-items: center; }
    .multiple-select-search .tags__item {
      color: #fff;
      border-radius: 4px;
      padding: 7px;
      background: #00b5d3;
      display: flex;
      align-items: center; }
      .multiple-select-search .tags__item svg {
        margin-left: 4px;
        cursor: pointer; }
      .multiple-select-search .tags__item:not(:last-child) {
        margin: 0 6px 0 0; }

.rich-comment {
  display: flex;
  flex-direction: column;
  padding: 16px 0; }
  .rich-comment textarea {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #acdae6;
    outline: none;
    box-shadow: none; }

.upload-area {
  background: #fff;
  padding: 8px;
  border-radius: 4px; }
  .upload-area button {
    background: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;
    justify-content: center;
    align-items: center;
    outline: none; }
    .upload-area button svg {
      margin-bottom: 16px; }

.profile ul li {
  background: #3c6d95;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer; }
  .profile ul li.is-active {
    background: #fff;
    color: #003d6f; }

.remuneration > div {
  border-radius: 8px; }

.remuneration span svg {
  cursor: pointer; }

.remuneration span:hover::after {
  content: '';
  position: absolute;
  width: 100%;
  background: transparent;
  left: 0;
  z-index: 9; }

.remuneration span:hover > .card {
  visibility: visible !important; }

.remuneration span .card {
  background: #FFFFFF;
  box-shadow: 4px 0px 8px rgba(0, 61, 113, 0.05), 8px 0px 80px rgba(0, 61, 113, 0.1);
  border-radius: 8px;
  position: absolute;
  padding: 16px;
  width: 300px;
  left: 50%;
  top: 32px;
  transform: translateX(-50%);
  visibility: hidden;
  pointer-events: none;
  z-index: 1; }
  .remuneration span .card .english-tooltip ul {
    padding: 8px; }
    .remuneration span .card .english-tooltip ul li {
      font-size: 14px;
      line-height: 141%;
      display: flex;
      align-items: flex-start;
      margin-left: 12px;
      position: relative; }
      .remuneration span .card .english-tooltip ul li:before {
        content: '';
        width: 5px;
        height: 5px;
        background: #003D71;
        display: inline-block;
        border-radius: 50%;
        margin-right: 6px;
        position: absolute;
        top: 6px;
        left: -12px; }
  .remuneration span .card h4 {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #F2F2F4; }
  .remuneration span .card .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; }
    .remuneration span .card .content hr {
      border-bottom: 1px solid #F2F2F4;
      border-top: none;
      width: 100%;
      margin: 8px 0;
      display: block; }
    .remuneration span .card .content strong {
      margin-bottom: 8px; }
    .remuneration span .card .content p {
      padding: 0;
      margin: 0; }

.remuneration .row {
  display: flex; }

.remuneration__col {
  display: flex;
  flex: 1;
  flex-direction: column; }
  .remuneration__col .d-block {
    display: flex;
    flex: 1;
    width: 100%; }
    .remuneration__col .d-block > svg {
      margin-left: 8px; }
