.multiple-select-search {
  background: #fff;

  .search-field {
    flex-wrap: wrap;
  }

  .results {
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    background: #fff;
    z-index: 100;
    flex-direction: column;
    -webkit-box-shadow: 5px 5px 15px 5px #b5b5b5;
    box-shadow: 5px 5px 15px 5px #efefef;

    span {
      padding: 16px 8px;
      cursor: pointer;
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid #efefef;
      }
    }
  }

  input {
    padding-left: 16px;
    width: 100%;
  }

  .tags {
    padding: 8px;
    display: flex;
    align-items: center;

    &__item {
      color: #fff;
      border-radius: 4px;
      padding: 7px;
      background: #00b5d3;
      display: flex;
      align-items: center;

      svg {
        margin-left: 4px;
        cursor: pointer;
      }

      &:not(:last-child) {
        margin: 0 6px 0 0;
      }
    }
  }
}
