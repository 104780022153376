.upload-area {
  background: #fff;
  padding: 8px;
  border-radius: 4px;

  button {
    background: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;
    justify-content: center;
    align-items: center;
    outline: none;

    svg {
      margin-bottom: 16px;
    }
  }
}
