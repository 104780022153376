.button {
  padding: 12px;
  outline: none;
  background: transparent;
  border-radius: 0;
  appearance: none;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover, &:active, &:visited {
    outline: none;
    box-shadow: none;
  }

  &.func-button {
    &:active {
      transform: scale(0.95);
    }
  }

  &:disabled {
    opacity: .2;
  }

  > svg {
    margin-right: 8px;
    margin-left: 8px;
  }

  &--rounded {
    border-radius: 6px;
  }

  &--medium {
    padding-left: 68px;
    padding-right: 68px;
  }

  &--c-black {
    color: #000 !important;
  }

  &--blue {
    background: #00B5D4 !important;
    color: #fff !important;
  }

  &--primary {
    background: #003D71 !important;
    color: #fff !important;
  }

  &--blue-outline {
    background: transparent !important;
    border: 1px solid #00B5D4 !important;
    color: #00B5D4 !important;
  }

  &--white {
    background: #fff !important;
    color: #00B5D4;

    &:not(:last-child) {
      border-right: 1px solid #E4E6E7;
    }
  }

  &--outline {
    background: transparent;
    color: #00B5D4;
    border-color: #00B5D4
  }

  &--full-outline {
    background: transparent !important;
    color: #00B5D4;
    text-decoration: none;
    border: none !important;
    outline: none;
  }
}
