.sliding-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: auto;

  &__visible {
    visibility: visible;
    z-index: 99999999;

    .sliding-modal__content {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__backdrop {
    position: fixed;
    background: rgba(0,0,0,.4);
    width: 100%;
    height: 100%;
  }

  &__content {
    width: 50vw;
    max-width: 900px;
    background: #ECF8FB;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0s,opacity .3s 0s,transform .3s 0s;
    transform: translateX(100%);

    .close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      opacity: 1;
    }

     header {
      display: flex;
      flex: 1 1;
      align-items: center;
      background: #fff;
      padding-left: 32px;
      padding-right: 68px;
      height: 100px;
      justify-content: space-between;
    }

    .content {
      padding: 32px;
      display: flex;
      flex-direction: column;
    }
  }
}

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999999;

  &__backdrop {
    background: #000000;
    opacity: 0.4;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__content {
    width: 800px;
    background: #FFFFFF;
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    overflow: auto;

    .radioset-buttons {
      display: flex;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      button {
        appearance: none;
        width: 50px;
        height: 50px;
        border: 1px solid #00B5D4;
        background: transparent;
        color: #00B5D4;
        outline: none;

        &.active {
          background: #00B5D4;
          color: #fff;
        }
      }
    }

    table {
      margin-top: 40px;
      width: 100%;

      thead {
        th {
          color: #00B5D4;
          width: 20%;
          padding-bottom: 40px;
          text-align: center;

          &:first-child {
            color: #99999B;
            width: 40%;
            text-align: left;
          }
        }
      }

      tbody {
        tr {
          color: #003D71;
          width: 20%;

          td {
            padding: 20px 0;
            border-top: 1px solid #F2F2F4;
            color: #003D71;
            text-align: center;

            &:first-child {
              color: #00B5D4;
              width: 40%;
              text-align: left;
            }
          }
        }
      }
    }

  }

  header {
    background: rgba(255, 117, 0, 0.19);
    border-radius: 8px 8px 0px 0px;
    padding: 24px;
    display: flex;
    justify-content: center;

    &.blue {
      background: #E0F3F8;
    }
  }

  .content {
    padding: 24px 50px;
    margin: 0;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 122%;
      color: #003D71;
      text-align: center;
    }

    .title {
      font-weight: 500;
      font-size: 20px;
      color: #003D71;
      text-align: center;
      padding: 0;
      margin-top: 0;
    }

    p {
      padding: 0 50px;
      text-align: center;
      margin-top: 24px;
    }

    //textarea {
    //  height: 120px;
    //  min-height: initial;
    //  background: #F2F2F4;
    //  width: 100%;
    //  margin-top: 8px;
    //  border: none;
    //  padding: 12px 16px;
    //  box-sizing: border-box;
    //  outline: none;
    //}

    .actions {
      display: flex;
      justify-content: center;
      margin-top: 32px;

      button {
        padding: 12px 55px;
        appearance: none;
        border: 0;
        outline: none;

        &:disabled {
          opacity: .2;
        }

        &:not(:last-child) {
          margin-right: 24px;
        }

        &.blue {
          background: #00B5D4;
          border: 1px solid #00B5D4;
          color: #fff;
        }

        &.white {
          background: #fff;
          color: #00B5D4;
          border: 1px solid #00B5D4;
        }

        &.outline {
          color: #00B5D4;
          border: 1px solid #00B5D4;
          background: transparent;
        }
      }
    }

    .form-row {
      margin-top: 20px;
      flex-direction: column;

      span.price {
        position: absolute;
        top: 8px;
        right: 0;
        background: #003d71;
        height: calc(100% - 8px);
        border-radius: 2px;
        color: #fff;
        padding: 0 10px;
        line-height: 43px;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        align-items: center;
      }

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 141%;
        color: #003D71;
      }

      > div {
        position: relative;
        width: 100%;

        .search__results {
          padding: 24px 16px;
          //position: absolute;
          background: #FFFFFF;
          box-shadow: 0px 4px 8px rgba(0, 61, 113, 0.05), 0px 8px 80px rgba(0, 61, 113, 0.1), inset 0px 1px 0px rgba(0, 0, 0, 0.05);
          top: 100%;
          left: 0;
          width: 100%;
          z-index: 2040;
          max-height: 250px;
          overflow-y: auto;

          .consultant-line {
            display: flex;
            width: 100%;
            align-items: center;
            transition: background-color 350ms linear;
            cursor: pointer;
            padding: 8px;

            &:hover {
              background: #eee;
              border-radius: 4px;
            }

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            img {
              width: 32px;
              height: 32px;
              object-fit: cover;
              border-radius: 32px;
              margin-right: 16px;
            }
          }
        }
      }

      input[type='text'] {
        width: 100%;
        background: #F2F2F4;
        appearance: none;
        margin-top: 8px;
        padding: 12px 16px;
        border: 0;
        outline: none;
      }
    }

    .date {
      .input-clone {
        background: #fff;
        justify-content: space-between;

        > div {
          display: flex;
          align-items: center;
        }

        svg {
          margin-left: 24px;
        }
      }
    }

    .plage {

      input {
        display: none;
      }

      label {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:before {
          content: '';
          width: 28px;
          height: 28px;
          background: #F2F2F4;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          margin-right: 12px;
        }

        &.selected {
          &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background: #00B5D4;
            border-radius: 50%;
            top: 8px;
            left: 8px;
          }
        }

        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }

    .buttons-day {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      button {
        background: transparent;
        border: 0;
        font-size: 17px;
        color: #4D4D4F;
        outline: none;
        position: relative;

        &.selected {
          &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            top: 9px;
            left: 50%;
            background: #00B5D4;
            border-radius: 50%;
            transform: translateX(-50%);
          }
        }

        &:before {
          content: '';
          position: relative;
          width: 28px;
          height: 28px;
          display: block;
          background: #fff;
          border-radius: 50%;
          margin-bottom: 8px;
        }
      }
    }

    .blue-text {
      font-weight: normal;
      font-size: 17px;
      line-height: 141%;
      color: #003D71;
      text-align: left;
      padding: 0;

      strong {
        display: block;
        margin-top: 4px;
      }
    }
  }
}

.view-action-modal {
  .actions {
    position: sticky;
    bottom: 0;
    background: #f1f1f3;
    width: calc(100% + 100px);
    margin-left: -50px;
    padding: 12px;
  }
}
