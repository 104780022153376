table.listing {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 61, 113, 0.05);
  border-radius: 8px;

  thead {
    th {
      padding: 32px 8px 16px;
      position: relative;
      color: #99999B;
      cursor: pointer;

      &:first-child {
        padding-left: 16px;
      }

      &.active_sorting {
        color: #000;

        &:before {
          content: '';
          position: absolute;
          background: #f2fafc;
          height: 100%;
          left: 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          bottom: -12px;
          width: 100%;
          //z-index: -1;
        }

        span {
          &:last-child {
            background-color: #000;
            width: 18px;
            height: 18px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      &.opened {
        span {
          &:nth-child(2) {
            background-color: #99999B;
            width: 18px;
            height: 18px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      &:last-child {
        &:before {
          width: calc(100% - 16px);
        }
      }

      span {
        display: table;
        margin-top: 4px;
        position: relative;
      }

      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          height: calc(100% - 32px);
          width: 1px;
          background: #F2F2F4;
          bottom: 0;
          right: 0;
        }
      }

      .filter-box {
        position: absolute;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 4px 0px 8px rgba(0, 61, 113, 0.05), 8px 0px 80px rgba(0, 61, 113, 0.1);
        border-radius: 8px;
        width: 320px;
        z-index: 2040;
        top: 100%;

        &--right {
          right: 16px;
        }

        hr {
          border: 1px solid #F2F2F4;
        }

        button {
          appearance: none;
          background: transparent;
          border: 0;
          color: #000;
          outline: none;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      position: relative;

      &:last-child {
        td {
          padding-bottom: 32px;

          &:after {
            height: calc(100% - 42px) !important;
            bottom: 16px !important;
          }

          &:before {
            display: none;
          }
        }
      }

      .comment-line {
        position: relative;
        display: inline-block;

        > .more {
          position: absolute;
          bottom: -5px;
          z-index: 2040;
          cursor: pointer;
          color: #00b5d4;
          left: 50%;
          transform: translateX(-50%);
        }

        &--big {
          max-height: 56px;
          overflow: hidden;
          word-break: break-word;

          &:hover {
            max-height: initial;

            &:after {
              visibility: hidden;
            }
          }


          &:after {
            content: '';
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          }
        }
      }

      td {
        padding: 24px 8px 16px 8px;
        position: relative;
        color: #9b9b9b;
        vertical-align: middle;
        letter-spacing: 0.50px;
        //z-index: 14;

        &.active_sorting {
          &:before {
            content: '';
            position: absolute;
            background: #f2fafc;
            height: calc(100%);
            left: 0;
            border-bottom: 2px solid #f2f2f4;
            bottom: -12px;

          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          max-width: 220px;
          align-items: center;

          li {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            margin-right: 8px;

            sup {
              width: 20px;
              height: 20px;
              background: #0eb5d3;
              font-size: 12px;
              border-radius: 50%;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0;
              top: 0;
              margin-left: 4px;
              font-weight: bold;
            }
          }
        }

        .disabled {
          opacity: 0.2;
          pointer-events: none;
        }

        .card {
          background: #FFFFFF;
          box-shadow: 4px 0px 8px rgba(0, 61, 113, 0.05), 8px 0px 80px rgba(0, 61, 113, 0.1);
          border-radius: 8px;
          position: absolute;
          padding: 24px 16px;
          width: 240px;
          left: calc(100% + 6px);
          top: calc(50% - 4px);
          transform: translateY(-50%);
          visibility: hidden;
          pointer-events: none;
          z-index: 2040;

          > span {
            position: absolute;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
          }

          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 50%;
              margin-bottom: 12px;
            }

            h3 {
              font-style: normal;
              font-weight: 500;
              font-size: 17px;
              line-height: 141%;
              text-align: center;
              color: #000000;
            }

            strong {
              font-weight: 500;
              font-size: 14px;
              line-height: 145%;
              text-align: center;
              color: #003D71;
            }
          }
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #F2F2F4;
          bottom: -12px;
          left: 0;
        }

        &:first-child {
          padding-left: 16px;

          &:before {
            right: 0;
            left: initial;
          }
        }

        &:first-child,
        &:last-child {
          &:before {
            width: calc(100% - 16px);
          }
        }


        button {
          background: #003D71;
          border-radius: 8px;
          padding: 6px 12px;
          border: 0;
          color: #fff;
          font-size: 14px;
          position: relative;
          z-index: 200;

          &:hover {
            & + .card {
              visibility: visible;
              pointer-events: initial;
              z-index: 2040;
            }
          }
        }

        span {
          display: table;
          line-height: 145%;
          font-size: 14px;
          box-sizing: border-box;
          position: relative;

          &.link {
            color: #000;
            max-width: 316px;
            font-weight: bold;
            text-decoration: underline;
          }

          &.client {
            max-width: 190px;
            color: #00B5D4;
          }

          &.black {
            color: #000;
          }
        }

        &:not(:last-child) {
          &:after {
            content: '';
            position: absolute;
            height: calc(100% - 24px);
            width: 1px;
            background: #F2F2F4;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
}

.listing-pagination {
  justify-content: center !important;
  align-items: center;

  .prev, .next {
    cursor: pointer;
  }

  .pagination-numbers {
    padding: 0 22px;

    input {
      background: #FFFFFF;
      border-left: 1px solid #00B5D4;
      border-top: 1px solid #00B5D4;
      border-bottom: 1px solid #00B5D4;
      border-right: none;
      padding: 8px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #00B5D4;
      text-align: center;
      outline: none;
    }

    .total-pages {
      background: rgba(153, 153, 155, 0.1);
      border-right: 1px solid #BFBFC2;
      border-top: 1px solid #BFBFC2;
      border-bottom: 1px solid #BFBFC2;
      border-left: 1px solid #BFBFC2;
      padding: 8px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
