#root {
  display: flex;
  flex-flow: column;
}

.wrapper {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

main.content {
  margin: 20px 0;
}
