.t-label {
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  color: #737375;
  font-size: 14px;

  &-large {
    font-size: 20px;
    color: #00B5D4;
  }

  &-small {
    line-height: 145%;
    color: #000000;
  }

  &-medium {
    line-height: 145%;
    color: #00B5D4;
    font-size: 17px;
  }

  &-gray {
    font-size: 20px;
    line-height: 140%;
    color: #4D4D4F;
  }

  &-gray-mid {
    font-size: 14px;
    line-height: 140%;
    color: #737375;
  }

  &-gray-dark {
    font-size: 20px;
    line-height: 140%;
    color: #4D4D4F;
    position: relative;
  }
}

.t-base {
  font-family: "filson-pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  color: #00B5D4;

  &-small {
    font-size: 14px;
  }

  &-medium {
    font-size: 17px !important;
    line-height: 141% !important;
    color: #0C0C0E !important;
  }

  &-large {
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
}

.t-header {
  font-family: "filson-pro", sans-serif;
  font-style: normal;
  line-height: 135%;
  color: #003D71;
  font-size: 24px;
  font-weight: bold;

  &-large {
    font-size: 30px;
    line-height: 36px;
    color: #222222;
    font-weight: bold;
  }

  &-small {
    font-size: 20px;
    color: #00B5D4;
  }

  &-x-large {
    font-size: 20px;
    color: #000;
    font-weight: 700;
  }
}

.u-color-primary {
  color: #003D71 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.c-white {
  color: white !important;
}
.c-blue {
  color: #00B5D4 !important;
}

.c-red {
  color: red !important;
}

.c-black {
  color: #000000 !important;
}

.c-orange {
  color: #FFA300 !important;
}

.bg-red {
  background: #f44336;
}

.bg-green {
  background: #8bc34a;
}
