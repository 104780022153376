.header {
  padding: 16px 0;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 999999;
  top: 0;
  background: #fff;

  &__wrapper {
    align-items: center;

    .brand {
      display: flex;
      align-items: center;
      height: auto;

      svg {
        margin-right: 16px;
        max-width: 48px;
      }
    }

    .navigation {
      .navigation__link {
        color: rgba(0,0,0,.5);
        font-weight: 600;
        color: #313131;
        letter-spacing: 1px;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  .admin-menu {

    button {
      background: #fff;
      padding: 8px 0
    }

    &__sub-menu {
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      min-width: 220px;
      background: #fff;
      z-index: 14;
      border: 1px solid #e1e1e1;

      button {
        width: 100%;
        text-align: right;
        justify-content: flex-end;
        outline: none;
        position: relative;
        padding: 12px;
        box-sizing: border-box;

        &:hover {
          background: #eee;
        }

        &:not(:last-child) {
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -1px;
            right: 0;
            background: #e1e1e1;
          }
        }
      }
    }
  }
}
