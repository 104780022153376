.row-form {
    display: flex;
    flex-wrap: wrap;

    &__line {
        display: flex;
        padding: 0 16px 12px 0;
        flex-direction: column;
        width: 50%;
        margin-bottom: 26px;
        position: relative;
        flex-grow: 1;

        &.fullW {
            width: 100%;
        }

        label {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 400;
            font-style: italic;
            flex: 1;
        }
    }
}

input {
    padding: 16px;
    width: auto;
    outline: none;
}

textarea {
    padding: 16px;
    background: #fff;
    outline: none;
    min-height: 120px;
}

.error {
    padding: 12px 0;
    color: #f44336;
    font-style: italic;
    position: absolute;
    left: 0;
    bottom: -25px;
}
