body {
  font-family: 'Roboto', sans-serif;
  color: #222222;
  font-size: 1rem;
  line-height: 1;
  background-color: #f5f7f8;
  margin: 0;
}

main {
  margin-top: 80px;
  display: flex;
  flex-flow: column;
}

.border-blue {
  border: 1px solid #8ebdc5;
}

.box {
  color: #222;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1.875rem;
  margin-bottom: 1.25rem;
  position: relative;
}

.has-tooltip {
  color: #000 !important;
  position: relative;

  &.cut-text {
    &:after {
      content: '';
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .content {
    margin-top: 0 !important;
  }

  &:hover {
    > .card {
      visibility: visible;

      ul {
        flex-direction: column;
        justify-content: flex-start;
        align-items: self-start;
        width: 100%;

        li {
          color: #003D71;
          &:last-child {
            margin-bottom: 0;
          }
          sup {
            background-color: transparent !important;
            color: #00B5D4;
          }
        }
      }
    }
  }
}

ul {
  li {
    padding-bottom: 8px;
    position: relative;

    &.active {
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        height: 2px;
        width: 100%;
        left: 0;
        border: 1px solid;
      }
    }
  }
}

.star-rating {
  svg {
    max-width: 16px;
  }
}

.global-search {
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 16px;
  min-width: 290px;
  border: 1px solid #8fbdc5;
  margin-left: 16px;
}

.filter-list-input {
  background: #FFFFFF;
  padding: 8px;
  color: #99999B;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  border: 1px solid #dfdfe2;
}

.tag {
  padding: 0px 8px;
  border-radius: 4px;
  display: block !important;
  margin-bottom: 8px;

  &--blue {
    background: rgb(0, 181, 211);
    color: rgb(255, 255, 255);

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.quill {
  background-color: #ffffff;
}
