.profile {
  ul {
    li {
      background: #3c6d95;
      padding: 6px 12px;
      border-radius: 4px;
      font-weight: 900;
      text-transform: uppercase;
      cursor: pointer;

      &.is-active {
        background: #fff;
        color: #003d6f;
      }
    }
  }
}

.remuneration {
  > div {
    border-radius: 8px;
  }

  span {
    svg {
      cursor: pointer;
    }

    &:hover {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        background: transparent;
        left: 0;
        z-index: 9;
      }

      > .card {
        visibility: visible !important;
      }
    }

    .card {
      background: #FFFFFF;
      box-shadow: 4px 0px 8px rgba(0, 61, 113, 0.05), 8px 0px 80px rgba(0, 61, 113, 0.1);
      border-radius: 8px;
      position: absolute;
      padding: 16px;
      width: 300px;
      left: 50%;
      top: 32px;
      transform: translateX(-50%);
      visibility: hidden;
      pointer-events: none;
      z-index: 1;

      .english-tooltip {
        ul {
          padding: 8px;
          li {
            font-size: 14px;
            line-height: 141%;
            display: flex;
            align-items: flex-start;
            margin-left: 12px;
            position: relative;

            &:before {
              content: '';
              width: 5px;
              height: 5px;
              background: #003D71;
              display: inline-block;
              border-radius: 50%;
              margin-right: 6px;
              position: absolute;
              top: 6px;
              left: -12px;
            }
          }
        }
      }

      h4 {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #F2F2F4;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        hr {
          border-bottom: 1px solid #F2F2F4;
          border-top: none;
          width: 100%;
          margin: 8px 0;
          display: block;
        }

        strong {
          margin-bottom: 8px;
        }

        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .row {
    display: flex;
  }

  &__col {
    display: flex;
    flex: 1;
    flex-direction: column;

    .d-block {
      display: flex;
      flex: 1;
      width: 100%;

      > svg {
        margin-left: 8px;
      }
    }
  }
}

