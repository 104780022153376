.ui-select {
  width: 170px;
  padding: 14px;
  border: 1px solid #8ebdc5;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  .choices {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    border: 1px solid #8ebdc5;
    left: 0;
    z-index: 1040;
    max-height: 162px;
    overflow: scroll;

    span {
      padding: 12px 16px;
      background: #fff;
      color: #0C0C0E !important;
      width: 100%;
      display: block;

      &:hover {
        background: #F2F2F4;
        color: #00B5D4 !important;
      }
    }
  }
}

.ui-dynamic-dropdown {
  width: 100%;
  color: #fff;
  position: relative;
  z-index: 1;

  &--open {
    z-index: 14;
  }

  > span {
    padding: 12px 16px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    color: #fff !important;
    cursor: pointer;
    position: relative;
    background: #003D71;
    border-radius: 6px;
    z-index: 1041;
    margin-right: 0 !important;

    label {
      color: inherit;
      font-size: 17px;
      line-height: 100%;
    }
  }

  .options {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 61, 113, 0.05), 0px 8px 80px rgba(0, 61, 113, 0.1), inset 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 100%;
    z-index: 1040;
    border: 1px solid #c4c4c4;
    max-height: 186px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &__item {
      padding: 9px 16px !important;
      font-weight: normal;
      font-size: 17px;
      line-height: 141%;
      color: #262628;
      cursor: pointer;
      min-width: initial !important;
      display: block !important;

      &--selected {
        background: #d9d9d9;
      }

      &:hover {
        background: #F2F2F4;
      }

      &:first-child {
        padding: 12px 16px 9px !important;
      }
    }
  }
}
