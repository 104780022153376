.rich-comment {
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  textarea {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #acdae6;
    outline: none;
    box-shadow: none;
  }

}
